#webamp .window::before {
  content: unset;
}
#webamp .title-bar {
  padding: 0;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

#webamp input[type="range"]::-webkit-slider-runnable-track,
#webamp input[type="range"]::-webkit-slider-thumb {
  background: none;
  border: 0;
  box-shadow: none;
  position: relative;
  top: 2px;
}

#webamp input[type="range"]::-moz-range-track,
#webamp input[type="range"]::-moz-range-thumb {
  background: none;
  border: 0;
  box-shadow: none;
}
