.video-container {
  position: relative;
  background: #000;
}
.video-container video {
  left: 50%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
