.notepad-container {
  display: block;
  font-family: "Courier New", monospace;
  font-size: 14px;
  padding: 4px;
  background: #fff;
}
.notepad-container pre {
  white-space: break-spaces;
  overflow: auto;
}
