/*! 7.css v0.3.0 - https://khang-nd.github.io/7.css */
/**
 * Core 7.css
 * Based on XP GUI.css by Adam Hammad <adamham.dev>
 * https://github.com/khang-nd/7.css/blob/main/LICENSE
 */

@import "_global.scss";
@import "_balloon.scss";
@import "_buttons.scss";
@import "_forms.scss";
@import "_groupbox.scss";
@import "_progressbar.scss";
@import "_slider.scss";
@import "_tabs.scss";
@import "_treeview.scss";
@import "_window.scss";
