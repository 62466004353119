/*-------------------------------------------*\
    Forms
\*-------------------------------------------*/
:root {
  --radio-width: 12px;
  --checkbox-width: 13px;
  --radio-label-spacing: 6px;
  --range-spacing: 10px;

  --radio-total-width-precalc: var(--radio-width) + var(--radio-label-spacing);
  --radio-total-width: calc(var(--radio-total-width-precalc));
  --radio-dot-width: 7px;
  --radio-dot-top: calc(var(--radio-width) / 2 - var(--radio-dot-width) / 2);
  --radio-dot-left: calc(
    -1 * (var(--radio-total-width-precalc)) + var(--radio-width) / 2 - var(
        --radio-dot-width
      ) / 2
  );

  --checkbox-total-width-precalc: 0;
  --checkbox-total-width: calc(var(--checkbox-total-width-precalc));
  --checkmark-width: 7px;
  --checkmark-top: 0;
  --checkmark-left: 2px;

  --thumb-w: 10px;
  --thumb-h: 18px;
  --thumb-icon: url("./icon/slider-indicator.png");
  --thumb-icon-box: url("./icon/slider-indicator-box.png");
  --thumb-icon-active: url("./icon/slider-indicator-active.png");
  --thumb-icon-box-active: url("./icon/slider-indicator-box-active.png");
}

label {
  font: var(--font);
  display: inline-flex;
  align-items: center;
}

input {
  font: var(--font);
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  font: var(--font);
  padding: 3px 4px 5px;
  border: 1px solid #ccc;
  border-top-color: var(--button-border-color);
  border-radius: 2px;
  background-color: var(--button-highlight);
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: var(--button-shade-light-hovered);
    border-top-color: var(--button-shade-light-active);
  }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  height: 23px;
  line-height: 2;
  &:focus {
    outline: none;
  }
}

select {
  font: var(--font);
  padding: 2px 3px;
  border: var(--button-border);
  border-color: var(--button-border-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 30px;
  background: svg-load("./icon/button-down.svg"),
    linear-gradient(
      to bottom,
      var(--button-face) 45%,
      var(--button-shade-light) 45%,
      var(--button-shade-dark)
    );
  background-position: center right;
  background-repeat: no-repeat;
  &:focus {
    outline: none;
    border-color: var(--button-border-color-active);
    background-image: svg-load("./icon/button-down.svg"),
      linear-gradient(
        to bottom,
        var(--button-face-active) 45%,
        var(--button-shade-light-active) 45%
      );
  }
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  background: 0;
  position: fixed;
  opacity: 0;
  border: none;
  + {
    label {
      line-height: 13px;
      position: relative;
      margin-left: var(--radio-total-width);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-1 * (var(--radio-total-width-precalc)));
        display: inline-block;
        width: var(--radio-width);
        height: var(--radio-width);
        margin-right: var(--radio-label-spacing);
        background: #fff;
        border-radius: 50%;
        border: 1px solid;
        border-color: var(--button-border-color);
        box-shadow: inset 0 0 0 1.5px #ddd, inset 1px 3px 4px #888;
        box-sizing: border-box;
      }
    }
  }
  &:active {
    + {
      label {
        &::before {
          background: svg-load("./icon/radio-border-disabled.svg");
        }
      }
    }
  }
  &:checked {
    + {
      label {
        &::after {
          content: "";
          display: block;
          width: var(--radio-dot-width);
          height: var(--radio-dot-width);
          top: var(--radio-dot-top);
          left: var(--radio-dot-left);
          position: absolute;
          background: #7cd3eb;
          border-radius: 50%;
          border: 1.5px solid #27506d;
          box-shadow: inset -1px -1px 0 0.5px #16638f,
            inset -1px -1px 0 1px #1985c0;
          box-sizing: border-box;
        }
      }
    }
  }
  &:focus {
    + {
      label {
        outline: 1px dotted #000000;
      }
    }
  }
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  background: 0;
  position: fixed;
  opacity: 0;
  border: none;
  + {
    label {
      line-height: 13px;
      position: relative;
      margin-left: var(--checkbox-total-width);
      &::before {
        content: "";
        display: inline-block;
        width: var(--checkbox-width);
        height: var(--checkbox-width);
        margin-right: var(--radio-label-spacing);
        background: #ddd;
        border: 1px solid;
        border-color: var(--button-border-color);
        box-shadow: inset 0 0 0 1.5px #eee, inset 0 2px 4px #888,
          inset -2px -3px 4px #fff;
        box-sizing: border-box;
      }
    }
  }
  &:focus {
    + {
      label {
        outline: 1px dotted #000000;
      }
    }
  }
  &:active {
    + {
      label {
        &::before {
          background: var(--surface);
        }
      }
    }
  }
  &:checked {
    + {
      label {
        &::after {
          content: "\2714";
          font-weight: bold;
          display: block;
          width: var(--checkmark-width);
          height: var(--checkmark-width);
          position: absolute;
          top: var(--checkmark-top);
          left: var(--checkmark-left);
          color: #16638f;
        }
      }
    }
  }
}

input[type="radio"][disabled],
input[type="checkbox"][disabled] {
  & + label {
    opacity: 0.6;
  }
}
