/*-------------------------------------------*\
    Buttons
\*-------------------------------------------*/

.button,
button {
  font: var(--font);
  box-sizing: border-box;
  border: var(--button-border);
  border-color: var(--button-border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--button-shadow);
  background: linear-gradient(
    to bottom,
    var(--button-face) 45%,
    var(--button-shade-light) 45%,
    var(--button-shade-dark)
  );
  min-width: 75px;
  min-height: 23px;
  padding: 0 12px;
  text-align: center;
  &:not(:disabled) {
    &:hover {
      border-color: var(--button-border-color-hovered);
      background: linear-gradient(
        to bottom,
        var(--button-face-hover) 45%,
        var(--button-shade-light-hovered) 45%
      );
    }
    &:active,
    &.active {
      box-shadow: none;
      border-color: var(--button-border-color-active);
      background: linear-gradient(
        to bottom,
        var(--button-face-active) 45%,
        var(--button-shade-light-active) 45%
      );
    }
  }
  &:focus,
  &.focused {
    box-shadow: inset 0 0 0 2px var(--button-shade-light-active);
    outline: 1px dotted #000000;
    outline-offset: -4px;
  }
}
